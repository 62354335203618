import React from 'react'
import { cancelActionAPI, UpdateRobotAction } from '../API';
import {toast } from 'react-toastify'
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

function CancelActionModel(props) {
   
    const CancelActionError=()=> toast.error('Cancel Action  Failed',{
        position: toast.POSITION.BOTTOM_RIGHT
    })
    const CancelActionSuccess=()=>toast.success('Cancel Action Command Success',{
        position: toast.POSITION.BOTTOM_RIGHT
    })
    const token=localStorage.getItem('token')
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        bgcolor: '#282F42',
        boxShadow: 24,
        p: 0,
        borderRadius:'10px'
    };   

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
  
    const handleClose = (reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setOpen(false)
   }
   const handleCancelActionbutton=(actionId,orderId)=>{
    cancelActionAPI(orderId,props?.fleetId,actionId,token)
    .then((res)=>{
      CancelActionSuccess()
    })
    .catch((err)=>{
      CancelActionError() 
    })
   } 
  
  return (
    <div>
        <button className="RobotActions-cancel-btn" onClick={handleOpen}> Cancel Action</button>  
         <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <div className='Cancel-order-Modal-Mainwrapper'>
                {/* <img src="/assets/images/cancel-order.png"/> */}
                <p> Do you want to do the </p>
                 <h5 style={{color:'rgb(228 51 41)'}}> "{props.name} action" ? </h5>
               
                {props.commandlistdata.actionPriority===1 ? 
                    <div className="RobotActions_popup_content_wrapper"> 
                        <div>
                          <p> ORDER ID </p>
                          <h4>{props.commandlistdata.orderId} </h4>
                        </div>
                       <div>
                          <p> LOCATION </p>
                          {props.commandlistdata.latitude || props.commandlistdata.longitude ?<h4> {props.commandlistdata.latitude} , {props.commandlistdata.longitude}</h4> : <h4>{props.commandlistdata.locatiionName}</h4>}  
                       </div>
                       <div>
                          <p> COMPARTEMENT </p>  
                          <h4>{props.commandlistdata.boxId} </h4> 
                       </div> 
                  </div>  :null } 
               <div className="Modal-button-Main-wrapper">
                 <Button className="Modal-button-Main-btn" onClick={handleClose}> No</Button>
                 <Button className="Modal-button-Main-btn yes-red-active"  onClick={()=> handleCancelActionbutton(props?.actionId,props.orderId)}> Yes</Button>
               </div>
           </div> 
        </Box>
      </Modal>
    </div>
  )
}

export default CancelActionModel
