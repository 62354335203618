import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { robotListfleetId } from '../API'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery80Icon from '@mui/icons-material/Battery80';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useDispatch } from 'react-redux';
import { robotlistdataAction, robotmsgAction } from '../redux/actions';
import ringer from "./alert.wav";
import io from 'socket.io-client';

function IndividualRobot(props) {
  const audioRef = useRef(null);
  const dispatch=useDispatch()
  const [robotlist, setrobotlist] =useState()   
  let robotmsg=[]
  let teleopsound=[]
  const token = localStorage.getItem('token')
  const navigate =useNavigate()
  const audio = new Audio(ringer);
  let socket
//  const serverUrl = 'http://localhost:3000';
 const serverUrl = 'https://hammerhead.ottonomy.io/';
 useEffect(()=>{
  socket = io(serverUrl,{transports:["websocket", "polling"]});

  robotListfleetId(props.data.fleetId,token)
  .then((res)=>{
    //  console.log('RobotStatus by FleetID Response',res)
     let robotDisplaydata = res.data.data
     setrobotlist(robotDisplaydata) 
       //  console.log('robotDisplaydata',robotDisplaydata)
     return robotDisplaydata
  })
  .catch((err)=>{
      // console.log('RobotStatus by FleetID Error',err)
  })
  setInterval(()=>{
  if(socket)
    {
      socket.emit("fleetDetails_hand_shake",props.data.fleetId)
      socket.on("fleetDetailsModel",(response)=>{
        // console.log(response)
        let robotDisplaydata = response.data
        setrobotlist(robotDisplaydata) 
      })
    }
  },5000)

},[])
 const [isCheck, setisCheck]=useState(true)
   
 const handlerobotdata=(robotdata)=>{
        // console.log('RobotDataRobotData',robotdata)
        //  dispatch(robotDataAction(robotdata[0]))
        //  console.log('RobotDataRobotData',robotdata)
   localStorage.setItem('fleetId',robotdata[0].fleetId)
   navigate(`/dashboard/${robotdata[0].robotId}`,{state:{name:robotdata[0],latitude:props.latitude, longitude:props.longitude}})
} 

 const playbeepsound=()=>{
  function play(){
    audio.play() 
    audio.loop = true
    audio.muted=false
    audio.id="playaudio"
  } 
   play() 
  // console.log('audioaudio',audio)
 }
 var arraycontainsturtles=false
 useEffect(()=>{
  robotlist?.map((itm)=>{
      if(itm[0].teleop==="YES"){
        teleopsound.push(itm[0].teleop)
         // playbeepsound()
      }
  })
  // arraycontainsturtles = (teleopsound.indexOf("YES") > -1);
  // if(arraycontainsturtles===true){
  //         playbeepsound()
         
  // }
})
const getTeleopStatus=(item,data)=>{
  switch(item?.teleop){
    case "YES":
        // playbeepsound()
        robotmsg.push({
        fleetId:data?.fleetId,
        fleetName:data?.fleetName,
        robotStatus:item.robotStatus,
        robotId:item.robotId,
        robotBattery:item.robotBattery,
        latitude:data.latitude,
        longitude:data.longitude,
        teleop:item.teleop
      })
      if(isCheck){
        setInterval(()=>{
            dispatch(robotmsgAction(robotmsg))
            setisCheck(false)
            },4000)
          } 
    case "No":
              
    default:
      audio.loop = false
      return null  
  }
}
const getrobotStatus=(item,data)=>{
    switch(item?.robotStatus){
     case "AVAILABLE":
       return  <p className="text-left m-0 p-color-green">Available</p>
     case "UNAVAILABLE":
       return  <p className="text-left m-0 p-color-red"> UnAvailable</p>  
     case "CHARGING":
      return  <p className="text-left m-0 p-color-green">Charging</p> 
     case "ERROR":
      // playbeepsound()
       robotmsg.push({
         fleetId:data?.fleetId,
         fleetName:data?.fleetName,
         robotStatus:item.robotStatus,
         robotId:item.robotId,
         robotBattery:item.robotBattery,
         latitude:data?.latitude,
         longitude:data?.longitude,
         teleop:item.teleop
       })
       if(isCheck){
        setTimeout(()=>{
            dispatch(robotmsgAction(robotmsg))
            setisCheck(false)
            },4000)
       }
       return <p className="text-left m-0 p-color-red"> Error</p>
      default:
       return <p className="text-left m-0 p-color-red"> {item?.robotStatus}</p>  
      }
}

  return (
    <>
      {robotlist?.length > 0 ? 
      <div className='SelectPage-robotList-wrapper'>
      
         {/* <audio id="playaudio"> 
            <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" autoplay loop type="audio/ogg"></source>
         </audio> */}
              {/* {console.log('robotlistrobotlist',robotlist)}  */}
            {robotlist?.map((item)=>{
               return (
                <div className='border-blue-top SelectFleetPage-inner-li-div d-flex-center' onClick={()=>handlerobotdata(item)}> 
                    {/* <audio preload="auto" src="/static/media/alert.aa9b8554cbeaf7968e81.wav" autoplay="autoplay" loop id="playaudio"></audio> */}
                   <div>
                     <p className="SelectFleetPage-inner-li-div-robot-id" style={{color:'white'}}> {item[0]?.robotId}</p>
                     {getrobotStatus(item[0], props.data)}
                     {getTeleopStatus(item[0], props.data)}
                  </div>
               <div className="dashboard-left-side-div-one-inner-div"> 
                    <div  className="dashboard-left-side-div-one-wrapper-battery-div">
                      {/* <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'24px'}}/>
                    <p style={{fontSize:'10px'}}>{parseInt(item[0]?.robotBattery).toFixed(0)}%</p>  */}
                     <div class="battery-icon-css">
                          <span style={{width:'30px', border:'1px solid #fff', borderRadius: '4px', padding:'2px', height: '14px', display: 'inline-block'}}>
                            <strong style={{background: parseInt(item[0]?.robotBattery).toFixed(0)<=20 ?'#E4261C':'rgb(113, 207, 98)', width:`${parseInt(item[0]?.robotBattery).toFixed(0)}%`, display:'block', height:'8px'}}></strong>
                         </span>
                     </div>     
                     {/* {console.log('item[0]?.robotBattery',item[0]?.robotBattery)}         */}
                      <p>{parseInt(item[0]?.robotBattery).toFixed(0)}%</p> 
                  </div> 
                     {/* <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/> */}
                      <span style={{background: '#fff', width:'3px', height:'5px',  display:'inline-block', borderRadius: '3px',marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'10px', display:'inline-block', borderRadius: '3px',marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'15px', display:'inline-block', borderRadius: "3px",marginRight:'3px'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'20px', display:'inline-block', borderRadius: '3px',marginRight:'3px',opacity:'0.5'}}></span>
                      <span style={{background: '#fff', width:'3px', height:'25px', display:'inline-block', borderRadius: '3px',marginRight:'3px',opacity:'0.5'}}></span>
                      {item[0]?.robotStatus==="AVAILABLE" ? <PauseCircleIcon className="play-icon p-color-green"/>:<PlayCircleIcon className="play-icon p-color-red"/>}
             </div> 
          </div> 
          
             )})}
       </div> 
       : <div className='SelectPage-robotList-wrapper'> 
         <h4 className='No-robots-available'> No Robots Available</h4>        
         </div>}
       </>
  )
}

export default React.memo(IndividualRobot)