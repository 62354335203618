import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Autorunplay, DisengageMotors, getrobotStatus, Openssh, RobotModeById } from '../API';

function AutorunMoving(props) {
   
    const [robotDataWhole,setrobotDataWhole] = useState()
    const [isOpen, setIsOpen] =useState()  
    const RunningStateError=()=> toast.error('Running State Failed',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const RunningStateSuccess=()=>toast.success('Running State Success',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    useEffect(()=>{
            // setInterval(()=>{
            //     getrobotStatus(props.robotId,token)
            //     .then((res)=>{
            //       setIsOpen(true)
            //       setrobotDataWhole(res.data.data.robotStatus)
            //     })
            //     .catch((err)=>{
            //     })
            // },8000)    
        },[])
            const [open, setOpen] = React.useState(false);
            const handleOpen = () => {
            setOpen(true)
        }
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 825,
      bgcolor: '#282F42',
      p: 4,
      borderRadius: "11px",
      boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)"
    };
    const token = localStorage.getItem('token')
    const handleClose = (reason) => {
        if (reason && reason == "backdropClick") 
            return;
         setOpen(false);
      }
      const handleautorunmoving=(robotId, runningState)=>{
        RobotModeById(robotId,token,runningState)
        .then((res)=>{
            // console.log('Autorunplay Response', res)
            RunningStateSuccess()
        })
        .catch((err)=>{
            // console.log('Autorunplay Error', err)
            RunningStateError()
         })
        handleClose()
      } 
      
   const getRobotRunningState=(robotRunningState)=>{
    // console.log('OPEN SSH',robotRunningState)  
    switch(robotRunningState){
      case "ERROR":
        return <button className="robotRunningState_autoSystemFault" onClick={handleOpen}> Error  </button>
      case "AUTO_RUN_OBSTACLE":
        return <button className="robotRunningState_teleop_Autorun" onClick={handleOpen}> AUTO_OBSTACLE  </button> 
      case "AUTO_RUN":
        return <button className="robotRunningState_teleop_Autorun" onClick={handleOpen}> AUTO_RUN  </button> 
      case "TELE_OP_OBSTACLE":
        return <button className="robotRunningState_state_Auto_Obstacle-Teleop_Obstacle" onClick={handleOpen}> TELE_OP_OBSTACLE  </button> 
      case "TELE_OP":
          return  <button className="robotRunningState_state_Teleop_Autorun" onClick={handleOpen}> TELE_OP  </button>  
      case "MANUAL":
          return <button className="robotRunningState_state_Teleop_Autorun" onClick={handleOpen}> MANUAL  </button>
      case "AUTO_SYSTEM_FAULT":
          return <button className="robotRunningState_autoSystemFault" onClick={handleOpen}> AUTO_SYSTEM_FAULT  </button>
      case "AUTO_OBSTACLE":
          return <button className="robotRunningState_state_Auto_Obstacle-Teleop_Obstacle" onClick={handleOpen}> AUTO_OBSTACLE  </button>
      case "AUTO_RUN_MOVING":
          return <button className="robotRunningState_autorunmoving" onClick={handleOpen}> AUTO RUN MOVING </button>  
      case "TELE_OP_MOVING":
          return <button className="robotRunningState_teleopmoving" onClick={handleOpen}> TELE_OP_MOVING</button> 
      case "PAYLOAD_STATE":
          return <button className="robotRunningState_payloadState" onClick={handleOpen}> PAYLOAD_STATE </button>              
      default:
          return <button className="robotRunningState_default" onClick={handleOpen}>{robotRunningState} </button>
        }}
  
  const getrobotmodalbuttons=(robotRunningState)=>{
    switch(robotRunningState){
        case "AUTO_SYSTEM_FAULT":
            return (<>
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,'AUTO_RUN_MOVING')}> AUTO_RUN_MOVING</button>
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"TELE_OP_MOVING" )}> TELE_OP_MOVING</button>
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,'PAYLOAD_STATE')}>   PAYLOAD_STATE</button>
            </>)
        case "AUTO_OBSTACLE":
            return( <>  
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,'AUTO_RUN_MOVING')}> AUTO_RUN_MOVING</button>
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,'PAYLOAD_STATE')}> PAYLOAD_STATE</button>
            <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"TELE_OP_MOVING" )}> TELE_OP_MOVING</button>
            </>)
        case "AUTO_RUN_MOVING":
            return(<>  
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"PAYLOAD_STATE" )}> PAYLOAD_STATE</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"AUTO_RUN" )}> AUTO_RUN</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"TELE_OP_MOVING" )}> TELE_OP_MOVING</button>
               </>)
        case "TELE_OP_MOVING":
            return(<>  
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"AUTO_RUN_MOVING" )}> AUTO_RUN_MOVING</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"PAYLOAD_STATE" )}> PAYLOAD_STATE</button>
               </>)
        case "PAYLOAD_STATE":
            return(<>  
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"AUTO_RUN_MOVING" )}> AUTO_RUN_MOVING</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"TELE_OP_MOVING" )}> TELE_OP_MOVING</button>
               </>)
        default:
          return(<>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"AUTO_RUN_MOVING" )}> AUTO_RUN_MOVING</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"TELE_OP_MOVING" )}> TELE_OP_MOVING</button>
               <button className="Modal-btn Modal-btn-red" onClick={()=>handleautorunmoving(props.robotId,"PAYLOAD_STATE" )}> PAYLOAD_STATE</button>
             </>)       
    }
 }
  return (
     <div className="RobotRunning_state_Button_wrapper">
    
     {getRobotRunningState(props.robotData?.robotStatus?.robotRunningState)}
     {/* {console.log('robotDataWhole',robotDataWhole)} */}
      <Modal
        backdrop="static"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
         <p className='Modal-wrapper-p'> 
           SWITCH TO
         </p>
        <div className='Modal-buttons-wrapper d-flex-center-center'> 
         {getrobotmodalbuttons(props.robotData?.robotStatus?.robotRunningState)}
        </div>
         <div className='d-flex-center-center' style={{marginTop:'20px'}}>
          <button className="Modal-btn" onClick={handleClose}>Cancel</button>  
         </div>
        </Box>
      </Modal>

    </div>
  )
}

export default React.memo(AutorunMoving)