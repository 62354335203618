import React, { useEffect, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import '../App.css'
import { Link, Navigate } from 'react-router-dom';
import { orderListFleetId } from '../API/index';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutModel from '../subcomponents/LogoutModel';

function Header(props) {
 const [orderList, setorderlist] =useState()
 const fleetID = localStorage.getItem('fleetID')
 useEffect(()=>{

 },[])

  const handlelogoclick=()=>{
    localStorage.setItem('useremail',null)
    localStorage.setItem('password',null)
    localStorage.setItem('token', null)
    localStorage.setItem('fleetID', null)
    localStorage.setItem('Isloggedin', "LoginFalse")
    localStorage.setItem('mapid',null)
  }
  const handleLogoutButton=()=>{
    localStorage.setItem('useremail',null)
    localStorage.setItem('password',null)
    localStorage.setItem('token', null)
    localStorage.setItem('fleetID', null)
    localStorage.setItem('Isloggedin', "LoginFalse")
    localStorage.setItem('mapid',null)
  }
  return (   
 <div className="Header-Main-wrapper">
  <div className='container-fluid'>
    <div className="row justify-content-end"> 
     
      <div className='col-md-12 text-center' style={{position:'relative'}}> 
      
           <Link onClick=''><img src="/assets/images/Ottonomy Logo_White_With TM-02.png" className="HeaderMain-logo-image"/>
           </Link>
    
    
           <LogoutModel header={true}/>
         {/* <Link to="/" onClick={handleLogoutButton} style={{width:'3%',position: "absolute",top: "20px", right: "0px"}}> 
             <PowerSettingsNewIcon style={{color:'#b1b1b1'}} className="Dashboard-Page-Power-button"/>
        </Link> */}
    
      </div>
    </div>
  </div>
  </div>
  )
}

export default Header