import { createStore,combineReducers } from 'redux'
import { robotdatareducer,robotlistReducer,fleetlatitudeReducer,fleetlongitudeReducer,fleetIdreducer,robotMessageReducer,robotidReducer,sshendpointReducer} from './reducers';

const mutipleReducers = combineReducers({
    fleetIdreducer,
    robotdatareducer,
    robotMessageReducer,
    robotidReducer,
    robotlistReducer,
    sshendpointReducer,
    fleetlatitudeReducer,
    fleetlongitudeReducer
})

const store = createStore(mutipleReducers);

export default store