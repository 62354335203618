import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { Route, Routes } from 'react-router-dom';
import SelectFleet from './components/SelectFleet';

function App() {
  return (
    <div className="App">
      <Routes> 
        <Route path="/" element={<Login/>}/>
        <Route path="dashboard/:id" element={<Dashboard/>} />
        <Route path='selectfleet/:email' element={<SelectFleet/>} />
    </Routes>    
    </div>
  );
}

export default App;
