import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DisengageMotors, getrobotStatus, Openssh, robotListByFleetId } from '../API';
import { toast , ToastContainer } from 'react-toastify';
import { dispatch } from 'd3';
import { sshendpointaction } from '../redux/actions';
function DisengageMotorsModal(props) {
    const token = localStorage.getItem('token')
    const OpenSSHError=()=> toast.error('SSh Session Command Failed',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const OpenSSHSuccess=()=>toast.success('SSh Session Command Sent',{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    const [IsOpen, setisOpen] =useState(true)
    let sshendpoint=''
    const sshEndPoint=(sshendpoint)=> toast(`Your SSH End Point is ${sshendpoint}`, {autoClose:false},{
      position: toast.POSITION.BOTTOM_RIGHT
  })
    useEffect(()=>{
            // getrobotStatus(props.robotId,token)
            // .then((res)=>{
            // //   console.log('Robot Status Open Ssh Response',res)
            //   setisOpen(true)
            // })
            // .catch((err)=>{
            //     // console.log('Robot Status Open Ssh Error',err)
            // })
    },[])
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    }
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 825,
      bgcolor: '#282F42',
      p: 4,
      borderRadius: "11px",
      boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)"
    };
    
    const handleClose = (reason) => {
        if (reason && reason == "backdropClick") 
            return;
         setOpen(false) 
         }
      const handleopenssh=(robotId ,sessionState)=>{
        Openssh(robotId, token , sessionState)
        .then((res)=>{
            // console.log('Open Ssh Response', res)    
            OpenSSHSuccess()
            setisOpen(!IsOpen)
        })
        .catch((err)=>{
        //   console.log('Open Ssh Error', err)
          OpenSSHError()
        })
        robotListByFleetId(props.fleetId,token).then((res)=>{
          res.data.data.map((item)=>{
            if(item.robotId===robotId) {
              sshendpoint =item.sshEndpoint
              // console.log('Item.sshpoint', item.sshEndpoint)
            }
          })
          sshEndPoint(sshendpoint)
          dispatch(sshendpointaction(sshendpoint))
        }).catch((err)=>{
            // console.log('SSH End Point Error',err)  
        })
        handleClose()
      }     
  return (
     <div>
       
     {IsOpen ? 
     <><button className="Dashboard_page_sidebar_button" onClick={handleOpen}> Open SSH</button> 
     <Modal
       backdrop="static"
       open={open}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description">
       <Box sx={style}>
        <p className='Modal-wrapper-p'> 
         Do you want to OPEN SSH?
        </p>
       <div className='Modal-buttons-wrapper d-flex-center-center'> 
        <button className="Modal-btn" onClick={handleClose}> No</button> 
        <button className="Modal-btn Modal-btn-red" onClick={()=>handleopenssh(props.robotId , "OPEN")}> Yes</button>
        </div>
       </Box>
     </Modal></> :
     <>
       <button className="Dashboard_page_sidebar_button" onClick={handleOpen}> Close SSH</button> 
      <Modal
        backdrop="static"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
         <p className='Modal-wrapper-p'> 
          Do you want to CLOSE SSH?
         </p>
        <div className='Modal-buttons-wrapper d-flex-center-center'> 
         <button className="Modal-btn" onClick={handleClose}> No</button> 
         <button className="Modal-btn Modal-btn-red" onClick={()=>handleopenssh(props.robotId , "CLOSE")}> Yes</button>
         </div>
        </Box>
      </Modal>
     </>
    }
    
    </div>
  )
}

export default React.memo(DisengageMotorsModal)