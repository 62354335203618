
export const userlogin=(data)=>{
    return{
        type: "USER_LOGIN",
        payload:data
    }
}
export const FleetIdAction=(data)=>{
    return{
        type: "FETCH_FLEET_ID",
        payload:data
    }
}
export const robotDataAction=(data)=>{
    return{
        type:"FETCH_ROBOT_DATA",
        payload:data
    }
}
export const robotmsgAction=(data)=>{
    return{
        type:"FETCH_ROBOTMSG_DATA",
        payload:data
    }
}
export const RobotIdAction=(data)=>{
    return{
        type: "GET_ROBOT_ID",
        payload:data
        
    }
}
export const robotlistdataAction=(data)=>{
    return{
        type: "FETCH_ROBOT_LIST_DATA",
        payload:data
        
    }
}
export const sshendpointaction=(data)=>{
    return{
        type:"FETCH_SSH_ENDPOINT",
        payload:data
    }
}
export const fleetlatitudeaction=(data)=>{
    return{
        type:"FETCH__FLEET_LATITUDE",
        payload:data
    }
}
export const fleetlogitudeaction=(data)=>{
    return{
        type:"FETCH__FLEET_LONGITUDE",
        payload:data
    }
}